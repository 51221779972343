<template>
  <div class="cultureRM home">
    <section class="culture-section1">
      <img src="@/assets/images/reviewmind/circle.png" alt="image" />
      <h2>{{ $t("culture_main_title") }}</h2>
      <div class="cultureCode">
        <div>
          <img src="@/assets/images/reviewmind/cultureCode1.png" alt="image" />
          <div class="textWrap">
            <h3>{{ $t("culture_title_1") }}</h3>
            <p>
              {{ $t("culture_title_context_1") }}
            </p>
          </div>
        </div>
        <div>
          <img src="@/assets/images/reviewmind/cultureCode2.png" alt="image" />
          <div class="textWrap">
            <h3>{{ $t("culture_title_2") }}</h3>
            <p>{{ $t("culture_title_context_2") }}</p>
          </div>
        </div>
        <div>
          <img src="@/assets/images/reviewmind/cultureCode3.png" alt="image" />
          <div class="textWrap">
            <h3>{{ $t("culture_title_3") }}</h3>
            <p>
              {{ $t("culture_title_context_3") }}
            </p>
          </div>
        </div>
        <div>
          <img src="@/assets/images/reviewmind/cultureCode4.png" alt="image" />
          <div class="textWrap">
            <h3>{{ $t("culture_title_4") }}</h3>
            <p>
              {{ $t("culture_title_context_4") }}
            </p>
          </div>
        </div>
        <div>
          <img src="@/assets/images/reviewmind/cultureCode5.png" alt="image" />
          <div class="textWrap">
            <h3>{{ $t("culture_title_5") }}</h3>
            <p>
              {{ $t("culture_title_context_5") }}
            </p>
          </div>
        </div>
        <div>
          <img src="@/assets/images/reviewmind/cultureCode6.png" alt="image" />
          <div class="textWrap">
            <h3>{{ $t("culture_title_6") }}</h3>
            <p>
              {{ $t("culture_title_context_6") }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="culture-section2">
      <h2>
        <img src="@/assets/images/reviewmind/circle.png" alt="image" />
        {{ $t("member") }}
      </h2>
      <ul>
        <li>
          <img src="@/assets/images/reviewmind/profile1.jpg" alt="image" />
          <h3>Sunny Kim</h3>
          <p>CEO / AI Developer</p>
          <p class="profile_info">
            San Diego State University
            <br />(Master in Information Systems) <br />{{
              $t("member_context_1_1")
            }}
            <br />{{ $t("member_context_1_2") }}
          </p>
          <a href="https://www.linkedin.com/in/sunny-kim-analytics/">
            <button>
              <img src="@/assets/images/reviewmind/linkedin.png" alt="image" />
            </button>
          </a>
        </li>
        <li>
          <img src="@/assets/images/reviewmind/profile2.jpg" alt="image" />

          <h3>Wonhyuk Jang</h3>
          <p>AI Developer</p>
          <p class="profile_info">
            University of Southern California
            <br />(Master in Computer Science) <br />{{
              $t("member_context_2_1")
            }}<br />{{ $t("member_context_2_2") }}
          </p>
          <a href="https://www.linkedin.com/in/wonhyuk-jang-44a941b4/">
            <button>
              <img src="@/assets/images/reviewmind/linkedin.png" alt="image" />
            </button>
          </a>
        </li>
        <li>
          <img src="@/assets/images/reviewmind/profile3.jpg" alt="image" />

          <h3>Yeonsu Choi</h3>
          <p>Digital Marketer</p>
          <p class="profile_info">
            {{ $t("member_context_3_university") }}
            <br />{{ $t("member_context_3_major") }} <br />{{
              $t("member_context_3_1")
            }}
            <br />{{ $t("member_context_3_2") }}
          </p>
          <a href="https://www.linkedin.com/in/yeonsu-choi-947597210">
            <button>
              <img src="@/assets/images/reviewmind/linkedin.png" alt="image" />
            </button>
          </a>
        </li>
      </ul>
    </section>
  </div>
</template>
<script>
import lang from "@/mixins/lang";
export default {
  mixins: [lang],
  created() {
    this.$store.dispatch("GNB_MENU", 4);
  },
};
</script>